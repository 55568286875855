//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuService from '../../services/MenuService';

export default {
	name: 'Footer',
	data() {
		const menuService = new MenuService();
		return {
			copy: menuService.getCopy(process.env.NUXT_ENV_MENU),
			legal: menuService.getLegal(process.env.NUXT_ENV_MENU),
			seals: menuService.getSeals(process.env.NUXT_ENV_MENU),
		};
	},
};
