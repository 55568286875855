export default class MenuService {
	constructor() {}

	getMenu(menu) {
		const menus = {
			lareverde: [
				{
					title: 'Tienda',
					url: '/',
				},
				{
					title: 'Conócenos',
					url: 'https://lareverde.org/conocenos/',
				},
				{
					title: 'Hazte Reverde',
					url: 'https://lareverde.org/asociate/',
				},
				{
					title: 'Contacto',
					url: 'https://lareverde.org/contacto/',
				},
				{
					title: 'Noticias',
					url: 'https://lareverde.org/noticias',
				},
				{
					title: 'Recetas',
					url: 'https://lareverde.org/recetas',
				},
			],
			karuvi: [
				{
					title: 'Tienda',
					url: '/',
				},
			],
		};
		return menus[menu];
	}

	getCopy(menu) {
		const links = {
			lareverde: [
				{
					title: 'Reverde S. Coop. And.',
					url: 'https://lareverde.org/',
				},
				{
					title: 'Bloonde',
					url: 'https://bloonde.com',
				},
			],
			karuvi: [
				{
					title: 'Karuvi',
					url: 'https://karuvi.es',
				},
			],
		};
		return links[menu];
	}

	getLegal(app) {
		const links = {
			lareverde: [
				{
					title: 'Aviso legal',
					url: 'https://lareverde.org/aviso-legal/',
				},
				{
					title: 'Política de cookies',
					url: 'https://lareverde.org/cookies/',
				},
				{
					title: 'Política de privacidad',
					url: 'https://lareverde.org/privacidad/',
				},
			],
			karuvi: [
				{
					title: 'Aviso legal',
					url: 'https://karuvi.es/legal/aviso-legal/',
				},
				{
					title: 'Política de cookies',
					url: 'https://karuvi.es/legal/cookies/',
				},
				{
					title: 'Política de privacidad',
					url: 'https://karuvi.es/legal/privacidad/',
				},
			],
		};
		return links[app];
	}

	getSeals(app) {
		const seals = {
			lareverde: [
				{
					type: 'LINK',
					image: '/img/subvencion-lareverde.png',
					url: 'https://lareverde.org/proyecto-financiado-por-fondos-feder/',
					alt: 'entidades',
				},
				{
					type: 'IMAGE',
					image: '/img/caae-double.webp',
					alt: 'CAAE',
					url: '',
				},
			],
			karuvi: [],
		};
		return seals[app];
	}
}
